<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customer from "@/models/customer.js";
import formVue from "@/components/form";
import { convertDateTime } from "@/_helpers";
import {
  saveProcess,
  setModelValue,
  //   scrollToError,
  objectToOptions,
} from "@/_helpers/index";
import axios from "axios";
import Multiselect from "vue-multiselect";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import Loading from "@/components/skeleton.vue";

const customerModel = new customer();

/**
 * Starter page
 */
export default {
  components: {
    Layout,
    PageHeader,
    formVue,
    Multiselect,
    convertDateTime,
    Loading,
  },
  page: {
    title: "Data Pelanggan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
        },
        {
          text: "Pelanggan",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      isLoading: false,
      model: customerModel.init(),
      setModel: [],
      apiUrl: {
        provinceUrl: process.env.VUE_APP_API_BASE_URL + "provinces",
        regencyUrl: process.env.VUE_APP_API_BASE_URL + "regencies",
        districtUrl: process.env.VUE_APP_API_BASE_URL + "districts",
        villageUrl: process.env.VUE_APP_API_BASE_URL + "villages",
        customerUrl: process.env.VUE_APP_API_BASE_URL + "customers",
      },
      setSelected: {
        selectedProvince: null,
        selectedRegency: null,
        selectedDistrict: null,
        selectedVillage: null,
      },
      provinsiOptions: [],
      kotaOptions: [],
      kecamatanOptions: [],
      desaOptions: [],
      villageLoading: false,
      rowCount: 9,
    };
  },
  watch: {
    "$route.params.id": function() {
      if (this.mode === "update") {
        this.getDetail();
      }
    },
    "setSelected.selectedRegency": function(newKota) {
      if (newKota) {
        this.fetchKecamatan();
      } else {
        this.kecamatanOptions = [];
        this.desaOptions = [];
      }
    },
    "setSelected.selectedDistrict": function(newKecamatan) {
      if (newKecamatan) {
        this.fetchDesa();
      } else {
        this.desaOptions = [];
      }
    },
    provinsiOptions(value) {
      if (value) {
        this.getUpdateProvince();
      }
    },
    kotaOptions(value) {
      if (value) {
        this.getUpdateRegency();
      }
    },
    kecamatanOptions(value) {
      if (value) {
        this.getUpdateKecamatan();
      }
    },
    desaOptions(value) {
      if (value) {
        this.getUpdateDesa();
      }
    },
  },
  validations: {
    setSelected: {
      // selectedProvince: {
      //   required,
      // },
      // selectedRegency: {
      //   required,
      // },
      // selectedDistrict: {
      //   required,
      // },
      // selectedVillage: {
      //   required,
      // },
    },
  },
  computed: {
    mode: function() {
      return this.$route.params.id !== undefined ? "update" : "create";
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    // provinceErrors() {
    //   return this.$v.setSelected.selectedProvince;
    // },
    // regencyErrors() {
    //   return this.$v.setSelected.selectedRegency;
    // },
    // districtErrors() {
    //   return this.$v.setSelected.selectedDistrict;
    // },
    // villageErrors() {
    //   return this.$v.setSelected.selectedVillage;
    // },
  },
  mounted() {
    if (this.mode === "update") {
      this.getDetail();
    }
  },
  created() {
    if (this.mode === "create") this.fetchProvince();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return convertDateTime(value);
      }
    },
    getOptions(type, key) {
      if (this.model[key] && this.model[key].options) {
        return objectToOptions(this.model[key].options);
        // }else if (this.model[key] && type == 'select') {
      } else {
        return null;
      }
    },
    getDetail() {
      this.isLoading = true;
      customerModel
        .find(this.$route.params.id)
        .then(async (resp) => {
          this.setModel = resp;
          setModelValue(this.model, resp);
          await this.fetchProvince();
          this.isLoading = false;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
        });
    },
    async getUpdateProvince() {
      this.setSelected.selectedProvince = this.provinsiOptions.find(
        (province) => province.id === this.setModel.province_id
      );
      await this.fetchRegency();
    },
    async getUpdateRegency() {
      this.setSelected.selectedRegency = this.kotaOptions.find(
        (regency) => regency.id === this.setModel.regency_id
      );
      await this.fetchKecamatan();
    },
    async getUpdateKecamatan() {
      this.setSelected.selectedDistrict = this.kecamatanOptions.find(
        (district) => district.id === this.setModel.district_id
      );
      await this.fetchDesa();
    },
    async getUpdateDesa() {
      this.setSelected.selectedVillage = this.desaOptions.find(
        (village) => village.id === this.setModel.village_id
      );
    },
    async fetchRegency() {
      if (this.setSelected.selectedProvince) {
        try {
          const response = await axios.get(
            `${this.apiUrl.regencyUrl}?province_id=${this.setSelected.selectedProvince.id}&limit=1000`
          );
          this.kotaOptions = response.data.data;
        } catch (error) {
          console.error("Error fetching kota:", error);
        }
      }
    },
    async fetchProvince() {
      try {
        const response = await axios.get(
          `${this.apiUrl.provinceUrl}?limit=100`
        );
        this.provinsiOptions = response.data.data;
      } catch (error) {
        console.error("Error fetching provinsi:", error);
      }
    },
    async fetchKecamatan() {
      if (this.setSelected.selectedRegency) {
        try {
          const response = await axios.get(
            `${this.apiUrl.districtUrl}?regency_id=${this.setSelected.selectedRegency.id}&limit=1000`
          );
          this.kecamatanOptions = response.data.data;
        } catch (error) {
          console.error("Error fetching kecamatan:", error);
        }
      }
    },
    async fetchDesa() {
      if (this.setSelected.selectedDistrict) {
        this.villageLoading = true;
        try {
          const response = await axios.get(
            `${this.apiUrl.villageUrl}?district_id=${this.setSelected.selectedDistrict.id}&limit=1000`
          );
          this.desaOptions = response.data.data;
          this.villageLoading = false;
        } catch (error) {
          console.error("Error fetching desa:", error);
        }
      }
    },
    onSubmit() {
      this.$nextTick(() => {
        let allValid = true;
        // let formData = [];
        Object.values(this.model).forEach((item, index) => {
          let childComponent = this.$refs.childComponentRef[index];
          if (
            childComponent &&
            childComponent.$v &&
            childComponent.$v.$invalid &&
            this.$v.$invalid
          ) {
            allValid = false;
            this.$v.$touch();
            return childComponent.getInvalidFeedback();
          }
        });
        if (!this.$v.$invalid) {
          saveProcess(this.model, this.mode === "update")
            .then(async (model) => {
              if (this.mode === "update") {
                const data = {
                  province_id: this.setSelected.selectedProvince ? this.setSelected.selectedProvince.id : null,
                  regency_id: this.setSelected.selectedRegency ? this.setSelected.selectedRegency.id : null,
                  district_id: this.setSelected.selectedDistrict ? this.setSelected.selectedDistrict.id : null,
                  village_id: this.setSelected.selectedVillage ? this.setSelected.selectedVillage.id : null,
                };
                const additionalData = model;
                const mergedDataSpread = {
                  ...data,
                  ...additionalData,
                };
                try {
                  await axios.patch(
                    `${this.apiUrl.customerUrl}/${this.$route.params.id}`,
                    mergedDataSpread
                  );
                  this.isLoading = false;
                  this.$router.push("/pelanggan");
                  this.$store.dispatch(
                    "notification/success",
                    "Data berhasil diubah"
                  );
                } catch (error) {
                  this.isLoading = false;
                  if (process.env.NODE_ENV !== "production") {
                    console.log(error);
                  }
                  this.$store.dispatch("notification/error", error);
                }
              } else {
                const data = {
                  province_id: this.setSelected.selectedProvince ? this.setSelected.selectedProvince.id : null,
                  regency_id: this.setSelected.selectedRegency ? this.setSelected.selectedRegency.id : null,
                  district_id: this.setSelected.selectedDistrict ? this.setSelected.selectedDistrict.id : null,
                  village_id: this.setSelected.selectedVillage ? this.setSelected.selectedVillage.id : null,
                };
                const additionalData = model;
                const mergedDataSpread = {
                  ...data,
                  ...additionalData,
                };
                try {
                  await axios
                    .post(`${this.apiUrl.customerUrl}`, mergedDataSpread)
                    .then((resp) => {
                      this.isLoading = false;
                      this.$router.push("/pelanggan");
                      this.$store.dispatch(
                        "notification/success",
                        "Data berhasil disimpan"
                      );
                    });
                } catch (error) {
                  this.isLoading = false;
                  if (process.env.NODE_ENV !== "production") {
                    console.log(error);
                  }
                  this.$store.dispatch("notification/error", error);
                }
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body v-if="!isLoading">
        <form @submit.prevent="onSubmit">
          <form-vue
            v-for="(m, index) in model"
            :key="index"
            :mode="mode"
            :type="m.type"
            :title="m.title"
            :value="m.value"
            :label="typeof m.label === 'string' ? m.label : null"
            v-model="m.value"
            :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null"
            :id="index"
            :with-url="m.url"
            :min-len="m.minLen"
            :max-len="m.maxLen"
            :validation-rules="m.validationRules"
            :invalid-feedback="m.invalidFeedback"
            ref="childComponentRef"
          />
          <b-form-group class="mb-3">
            <label for="provinsi">Provinsi</label>
            <multiselect
              v-model="setSelected.selectedProvince"
              :options="provinsiOptions"
              :searchable="true"
              :close-on-select="true"
              id="provinsi"
              placeholder="Pilih provinsi"
              label="name"
              track-by="id"
              @input="fetchRegency"
            ></multiselect>
            <!-- <p v-if="provinceErrors.$error" class="text-danger">
              Harap pilih provinsi
            </p> -->
          </b-form-group>

          <b-form-group class="mb-3">
            <label for="kota">Kota</label>
            <multiselect
              v-model="setSelected.selectedRegency"
              :options="kotaOptions"
              :searchable="true"
              :close-on-select="true"
              id="kota"
              placeholder="Pilih kota"
              label="name"
              track-by="id"
            ></multiselect>
            <!-- <p v-if="regencyErrors.$error" class="text-danger">
              Harap pilih Kota
            </p> -->
          </b-form-group>

          <b-form-group class="mb-3">
            <label for="kecamatan">Kecamatan:</label>
            <multiselect
              v-model="setSelected.selectedDistrict"
              :options="kecamatanOptions"
              :searchable="true"
              :close-on-select="true"
              id="kecamatan"
              placeholder="Pilih kecamatan"
              label="name"
              track-by="id"
              @input="fetchDesa"
            ></multiselect>
            <!-- <p v-if="districtErrors.$error" class="text-danger">
              Harap pilih kecamatan
            </p> -->
          </b-form-group>

          <b-form-group class="mb-3">
            <label for="desa">Desa:</label>
            <multiselect
              v-model="setSelected.selectedVillage"
              :options="desaOptions"
              :searchable="true"
              id="desa"
              placeholder="Pilih desa"
              label="name"
              track-by="id"
              deselect-label="Can't remove this value"
              :allow-empty="false"
              :loading="villageLoading"
              :internal-search="false"
              :clear-on-select="true"
              :close-on-select="true"
              @search-change="fetchDesa"
            ></multiselect>
            <!-- <p v-if="villageErrors.$error" class="text-danger">
              Harap pilih desa
            </p> -->
          </b-form-group>

          <b-button type="submit" variant="primary" class="mt-3 float-right"
            >Simpan</b-button
          >
        </form>
      </b-card-body>
      <b-card-body v-else>
        <div v-for="row in rowCount" :key="row" class="my-3">
          <loading class="my-3" width="35%" height="2rem" borderRadius="7px" />
          <loading width="100%" height="2rem" borderRadius="7px" />
        </div>
        <loading class="mt-4" width="10%" height="2rem" borderRadius="7px" />
      </b-card-body>
    </b-card>
  </Layout>
</template>
